import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { Hamburger, Close } from './svgIcon';
import Button from './button';
import ModalBox from './ModalBox';
import ContactUs from './Contact';

function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const [contactModal, setContactModal] = useState('close');
  const navigate = useNavigate();
  const handleClick = () => {
    setContactModal('open');
  };

  const scrollToSection = (targetId) => {
    setNavbar(false);
    const element = document.getElementById(targetId);
    if (element) {
      const topOffset = element.offsetTop - 100;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    } else {
      navigate('/');
    }
  };
  const handleHamburgerClick = () => {
    setNavbar(!navbar);
  };

  return (
    <div className="navbar-wrap">
      <ModalBox
        status={contactModal === 'open'}
        closeModal={() => setContactModal('close')}
        modalView={{ title: 'Contact Us', content: <ContactUs /> }}
      />
      <div className="navbar-container">
        <img src={logo} alt="logo" className="logo-img" />
        <div className="navbar-menu">
          <div className="menus">
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('home')}
            >
              Home
            </div>
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('about')}
            >
              About
            </div>
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('features')}
            >
              Features
            </div>
            <div
              className="menu"
              role="presentation"
              onClick={handleClick}
            >
              Contact
            </div>
          </div>
          <div className="navbar-button">
            <div className="signup-button">
              <Link label="signup" to="/register">
                <Button
                  name="Signup"
                />
              </Link>
            </div>
            <div className="login-button">
              <Link target="_blank" label="login" to={process.env.REACT_APP_LOGIN_URL}>
                <Button
                  name="Login"
                  disabled={false}
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className="hamburger-icon"
          role="button"
          tabIndex={0}
          onClick={handleHamburgerClick}
          onKeyDown={(e) => e.key === 'Enter' && handleHamburgerClick()}
          label="button"
        >
          <Hamburger />
        </div>
      </div>
      <div
        className="mobile-navbar"
        style={{
          right: navbar ? '0' : '-110%',
        }}
      >
        <div className="navbar-header">
          <div
            className="logo-icon"
          >
            <img src={logo} alt="logo" />
          </div>
          <div
            className="close-icon"
            role="presentation"
            onClick={() => setNavbar(false)}
            label="button"
          >
            <Close />
          </div>
        </div>
        <div className="mobile-menu-container">
          <div
            className="menu"
            role="presentation"
            onClick={() => scrollToSection('home')}
          >
            Home
          </div>
          <div
            className="menu"
            role="presentation"
            onClick={() => scrollToSection('about')}
          >
            About
          </div>
          <div
            className="menu"
            role="presentation"
            onClick={() => scrollToSection('features')}
          >
            Features
          </div>
          <div
            className="menu"
            role="presentation"
            onClick={handleClick}
          >
            Contact
          </div>
          <Link
            target="_blank"
            label="login"
            to={process.env.LOGIN_URL}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('login')}
            >
              Login
            </div>
          </Link>
          <div
            className="menu"
            role="presentation"
            onClick={() => scrollToSection('signup')}
          >
            Signup
          </div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
