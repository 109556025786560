import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Input from '../../../shared/form/Input';
import Button from '../../../shared/button';
import auth from '../../../utils/api/auth';
import ModalBox from '../../../shared/ModalBox';
import Success from '../../../shared/Success';

function UserForm(props) {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);

  const handleChange = (name, changeValue) => {
    props?.setFormData({ ...props?.formData, [name]: changeValue });
  };

  const submit = () => {
    setUploading(true);
    const userData = {
      name: props?.formData?.name,
      tenantId: parseInt(props?.formData?.tenantId, 10),
      userName: props?.formData?.userName,
      email: props?.formData?.userEmail,
      password: props?.formData?.password,
    };

    if (props?.formData?.companyEmail !== props?.formData?.userEmail) {
      auth.userRegister(userData)
        .then(() => {
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            if (props?.page === 'login') {
              navigate('../admin/dashboard');
            } else {
              navigate('/');
            }
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          setUploading(false);
          setErrorMsg('Unexpected error. please contact support');
        });
    } else {
      setUploading(false);
      setErrorMsg('Email ID already used in the company form.');
    }
  };

  useEffect(() => {
    if (
      props?.formData?.name
      && props?.formData?.userName
      && props?.formData?.password
      && props?.formData?.userEmail
    ) {
      setErrorMsg('');
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [props?.formData]);

  return (
    <div className="user-register-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message="Account Created Successfully" /> }}
      />
      <div className="single-row">
        <Input
          type="text"
          name="name"
          value={props?.formData?.name}
          change={handleChange}
          placeholder="Full Name"
        />
        <Input
          type="text"
          name="userName"
          value={props?.formData?.userName}
          change={handleChange}
          placeholder="Username"
        />
      </div>
      <Input
        type="text"
        name="userEmail"
        value={props?.formData?.userEmail}
        change={handleChange}
        readonly
        placeholder="Mail Id"
      />
      <Input
        type="password"
        name="password"
        value={props?.formData?.password}
        change={handleChange}
        placeholder="Password"
      />
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <Button
        name="Submit"
        click={submit}
        classes="success-button"
        disabled={disable}
        loading={uploading}
      />
    </div>
  );
}

export default UserForm;
