import React from 'react';
import aboutImg from '../../../assets/images/about.png';

function About() {
  return (
    <div className="about-wrap" id="about">
      <div className="about-content">
        <h2>About Access Me</h2>
        <p>
          Welcome to Access Me – Your Gateway to Intelligent Access Management!
          At AICTEC, we understand the critical importance of security, efficiency,
          and seamless access control in today&apos;s dynamic environments.
          Our Smart Access Management system is a cutting-edge solution
          designed to elevate the way you manage and control access to physical spaces.
          From high-security facilities to modern offices, our product ensures a
          holistic and intelligent approach to access control.
        </p>
      </div>
      <div className="about-img">
        <img src={aboutImg} alt="about_image" className="about_image" />
      </div>
      {/* <p>
          Our company solves numerous amount of challenges in this sector
          such as rejections, over production and reworked production using
          integrator application, appropriate cameras and sensors.
        </p>
        <p>
          Data analytics on the quality control process to provide insight on the
          performance lapse.
        </p>
        <p>
          Cut down inefficiency in the quality control system to reduce the
          overall expenses in manufacturing.
        </p> */}
    </div>
  );
}

export default About;
