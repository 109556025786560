import { post } from './base/index';

export default {
  sendVerificationEmail: (data = {}) => post(`${process.env.REACT_APP_API_URL}TenantManagement/VerifyEmail?email=${data?.email}`),
  verifyEmail: (data = {}) => post(`${process.env.REACT_APP_API_URL}TenantManagement/VerifyOTP?email=${data?.email}&otp=${data.otp}`),
  selfRegister: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}TenantManagement/CreateTenant`, { ...params });
  },
  userRegister: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}TenantUserManagement/CreateTenantUser`, { ...params });
  },
};
