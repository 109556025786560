import React from 'react';
import Header from './component/Header';
import Feature from './component/Feature';
import About from './component/About';
import TalkToUs from './component/TalkToUs';

function Home() {
  return (
    <div className="home">
      <Header />
      <About />
      <Feature />
      <TalkToUs />
    </div>
  );
}

export default Home;
