import React from 'react';

import feature1 from '../../../assets/images/features/feature1.png';
import feature2 from '../../../assets/images/features/feature2.png';
import feature3 from '../../../assets/images/features/feature3.png';
import feature4 from '../../../assets/images/features/feature4.png';
import feature5 from '../../../assets/images/features/feature5.png';
import feature6 from '../../../assets/images/features/feature6.png';

function Feature() {
  const featureData = [
    {
      id: 1,
      image: feature3,
      title: 'Biometric Brilliance',
      desc: `Embrace the future of access with state-of-the-art biometric recognition technology.
      Fingerprint scanning, facial recognition, and iris scanning for secure and convenient user authentication.`,
    },
    {
      id: 2,
      image: feature1,
      title: 'Cloud-Powered Efficiency',
      desc: `Revolutionize your access control with our cloud-based platform.
      Real-time access to data, centralized control, and seamless configuration from anywhere in the world.`,
    },
    {
      id: 3,
      image: feature2,
      title: 'Role-Based Access Control (RBAC)',
      desc: `Tailor access permissions with precision using our Role-Based Access Control feature.
      Define roles, assign permissions, and ensure the right individuals have the right level of access.`,
    },
    {
      id: 4,
      image: feature5,
      title: 'Time-Based Access',
      desc: `Enhance security during off-hours with time-sensitive access control policies.
      Define specific time frames for access, adding an extra layer of protection to your premises.`,
    },
    {
      id: 5,
      image: feature4,
      title: 'Integration Harmony',
      desc: `Seamlessly integrate with your existing security systems for comprehensive protection.
      Connect with surveillance cameras, alarms, and building management systems for a unified security solution.`,
    },
    {
      id: 6,
      image: feature6,
      title: 'Real-Time Monitoring and Alerts',
      desc: `Stay ahead of potential threats with real-time monitoring and instant alerts.
      Receive notifications for unauthorized access attempts, ensuring a swift response to security incidents.`,
    },
  ];
  return (
    <div className="feature-wrap" id="features">
      <h1>Features</h1>
      <div className="feature-container">
        {featureData?.map((item) => (
          <div key={item?.id} className="each-feature">
            {/* <div className="img-container">
              <img src={item?.image} alt="featureImage" className="featureImage" />
            </div> */}
            <h2>{item?.title}</h2>
            <p>{item?.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Feature;
