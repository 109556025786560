/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function SelectBox(props) {
  const changes = (event, newValue) => {
    props?.onchange(props?.name, newValue);
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <Autocomplete
        multiple={props?.multiple}
        value={props?.value}
        name={props?.name}
        onChange={changes}
        options={props?.lists}
        id={props?.id}
        disableClearable={props?.disableClearable}
        sx={{
          ".MuiAutocomplete-input": { fontSize: '14px', fontFamily: '"Montserrat", sans-serif;' },
          ".MuiOutlinedInput-root": { padding: '4.5px 4.5px' },
          ".MuiOutlinedInput-notchedOutline": {
            border: props?.border === false ? 'none !important' : '0.5px solid #CACACA !important',
            borderRadius: '6px !important',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props?.placeholder}
          />
        )}
        renderOption={(prop, option) => (
          <li {...prop}>
            <span id={option?.label}>{option?.label}</span>
          </li>
        )}
      />
    </div>
  );
}

SelectBox.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  lists: PropTypes.arrayOf(PropTypes.array.isRequired),
  onchange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  border: PropTypes.bool,
  disableClearable: PropTypes.bool,
};
SelectBox.defaultProps = {
  multiple: false,
  label: '',
  value: '',
  lists: [],
  border: true,
  disableClearable: false,
};

export default SelectBox;
