import React from 'react';
import PropTypes from 'prop-types';
import success from '../assets/images/success.png';

function Success(props) {
  return (
    <div className="success-wrap">
      <img src={success} alt="tick" className="success-img" />
      <h2>{props?.message}</h2>
    </div>
  );
}

Success.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Success;
