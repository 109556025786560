import React, { useState } from 'react';
import Input from './form/Input';
import Button from './button';
import contact from '../utils/api/contact';
import ModalBox from './ModalBox';
import Success from './Success';

function ContactUs() {
  const message = <>Thank you for your enquiry. <br /> We Will contact you soon!</>;
  const [value, setValue] = useState({});
  const [notification, setNotification] = useState(false);
  const handleChange = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const handleSubmit = () => {
    contact.sendInformation(value)
      .then(() => {
        setValue({
          name: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <ModalBox
        status={notification}
        closeModal={() => setNotification(false)}
        modalView={{ content: <Success message={message} /> }}
        notification
      />
      <div className="contact-input">
        <Input
          name="name"
          label="Name"
          placeholder="Enter your Name"
          type="text"
          value={value.name}
          change={handleChange}
          required
        />
        <Input
          name="email"
          label="Work Email"
          placeholder="Enter your Work Mail "
          type="text"
          value={value.email}
          change={handleChange}
          required
        />
        <Input
          name="phoneNumber"
          label="Phone number"
          placeholder="Enter your Phone Number"
          type="number"
          value={value.phoneNumber}
          change={handleChange}
          required
        />
        <Input
          className="message-box"
          name="message"
          type="textarea"
          label="Message"
          value={value.message}
          change={handleChange}
        />
        <Button
          type="submit"
          name="Submit"
          click={handleSubmit}
        />

      </div>
    </div>
  );
}

export default ContactUs;
