import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import footerLogo from '../assets/images/footer/accessme_footer.png';
import ModalBox from './ModalBox';
import ContactUs from './Contact';

function Footer() {
  const navigate = useNavigate();

  const [contactModal, setContactModal] = useState('close');
  const handleClick = () => {
    setContactModal('open');
  };

  const scrollToSection = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      const topOffset = element.offsetTop - 100;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handlePrivacyPolicy = () => {
    navigate('./privacy-policy');
  };

  return (
    <div className="footer-wrap" id="footer">
      <ModalBox
        status={contactModal === 'open'}
        closeModal={() => setContactModal('close')}
        modalView={{ title: 'Contact Us', content: <ContactUs /> }}
      />
      <div className="footer-container">
        <img src={footerLogo} alt="logo-img" className="footer-logo" />
        <div className="footer-menu">
          <div className="menus">
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('home')}
            >
              Home
            </div>
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('features')}
            >
              Features
            </div>
            <div
              className="menu"
              role="presentation"
              onClick={() => scrollToSection('about')}
            >
              About
            </div>
            <div
              className="menu menu-last"
              role="presentation"
              onClick={handleClick}
              style={{ border: 'none' }}
            >
              Contact
            </div>
          </div>
        </div>
        {/* <div className="footer-rights">
        </div> */}
      </div>
      <div className="privacy">
        <p role="presentation" className="privacy-policy" onClick={handlePrivacyPolicy}>Privacy Policy</p>
        <p className="copyright">Copyright © 2024 AICTEC</p>
      </div>
      <p className="copyright-mobile">Copyright © 2024 AICTEC</p>
    </div>
  );
}

export default Footer;
