import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import closeCircle from '../assets/icon/close-circle.png';

function ModalBox(props) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.2px solid #bab8b8',
    boxShadow: 24,
    borderRadius: '0px',
    p: 3,
  };
  return (
    <Modal
      open={props?.status}
      onClose={() => props?.closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal-box">
          <div className="modal-header">
            <h1 className="modal-header-title">{props?.modalView?.title}</h1>
            <div
              role="presentation"
              className="modal-close-button"
              onClick={() => props?.closeModal()}
            >
              { !props?.notification && <img src={closeCircle} alt="sort" />}
            </div>
          </div>
          <div className="modal-body">
            {props?.modalView?.content}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

ModalBox.propTypes = {
  status: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalView: PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
  notification: PropTypes.bool,
};
ModalBox.defaultProps = {
  notification: false,
};

export default ModalBox;
