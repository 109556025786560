import React from 'react';
import PropTypes from 'prop-types';

function Input(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value);
  };

  return (
    <div className="input-wrap">
      {props?.label && <p>{props?.label}{props?.required && <span>*</span>}</p>}
      {props?.type === 'textarea' ? (
        <textarea
          name={props?.name}
          placeholder={props?.placeholder}
          className={`input-box ${props?.className}`}
          value={props?.value}
          onChange={changes}
          required={props?.required}
          autoComplete="off"
          style={{ resize: 'none' }}
        />
      ) : (
        <input
          name={props?.name}
          placeholder={props?.placeholder}
          type={props?.type}
          className={`input-box ${props?.className}`}
          value={props?.value}
          onChange={changes}
          required={props?.required}
          autoComplete="off"
          onWheel={(e) => e.target.blur()}
        />
      )}
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  change: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: '',
  required: false,
};

export default Input;
