import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../../shared/form/Input';
import Button from '../../../shared/button';
import auth from '../../../utils/api/auth';

function EmailValidation(props) {
  const [otp, setOtp] = useState();
  const [disable, setDisable] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [value, setValue] = useState();

  const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const generateOtp = () => {
    setDisable(true);
    setUploading(true);
    auth.sendVerificationEmail(value)
      .then((res) => {
        setUploading(false);
        console.log(res);
        setOtp(true);
        props?.setSubtitle('Enter the OTP sent to your email');
      })
      .catch((err) => {
        setUploading(false);
        setErrorMsg(err?.response?.data?.message);
      });
  };

  const validateOtp = () => {
    setUploading(true);
    auth.verifyEmail(value)
      .then((res) => {
        setUploading(false);
        console.log(res?.data);
        if (res?.data === 'OTP verification Successful') {
          props?.setSubtitle('');
          props?.setFormStage(2);
        } else {
          setErrorMsg('Invalid OTP');
        }
      })
      .catch(() => {
        setUploading(false);
        setErrorMsg('Entered Invalid OTP');
      });
  };

  const handleChange = (name, changeValue) => {
    if (name === 'email') {
      props?.setFormData({ ...props?.formData, [name]: changeValue });
      setValue({ ...value, [name]: changeValue });
    } else {
      setValue({ ...value, otp: changeValue });
    }
  };

  useEffect(() => {
    setErrorMsg('');
    if ((value?.email?.match(isValidEmail) && !otp) || value?.otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <div className="validation-wrap">
      <Input
        name="email"
        value={props?.formData?.email}
        change={handleChange}
        placeholder="Work mail or Phone number"
      />
      {otp
        && (
          <div className="otp">
            <Input
              type="text"
              name="otp"
              value={value?.otp}
              change={handleChange}
              placeholder="Enter OTP"
              maxLength={6}
            />
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          </div>
        )}

      <div className="form-body">
        <p>By creating an account, I Agree to AccessME’s <Link to="/">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy policy</Link></p>
      </div>
      <Button
        name={otp ? 'Verify OTP' : 'Send OTP'}
        click={otp ? validateOtp : generateOtp}
        classes={uploading ? ' success-button loading-btn' : 'success-button'}
        disabled={disable}
        loading={uploading}
      />
      <div className="have-an-account">
        <p>Already have an account?</p>
        <Link target="_blank" label="login" to={process.env.REACT_APP_LOGIN_URL}>
          Login
        </Link>
      </div>
    </div>
  );
}

export default EmailValidation;
