import React, { useState } from 'react';
import Button from '../../../shared/button';
import ModalBox from '../../../shared/ModalBox';
import ContactUs from '../../../shared/Contact';

import leftImg from '../../../assets/images/header/left.png';
import rightImg from '../../../assets/images/header/right.png';
import gifAnimation from '../../../assets/images/header/animation.gif';

function Header() {
  const [contactModal, setContactModal] = useState('close');
  const handleClick = () => {
    setContactModal('open');
  };
  return (
    <div className="header-wrap" id="home">
      <ModalBox
        status={contactModal === 'open'}
        closeModal={() => setContactModal('close')}
        modalView={{ title: 'Contact Us', content: <ContactUs /> }}
      />
      <h1>Your Building is Safe with Our Full- featured <span>Mobile Application </span>Control System</h1>
      <Button
        name="Request for a demo"
        disabled={false}
        click={handleClick}
      />
      <div className="absolute-wrap">
        <img src={leftImg} alt="header" className="absolute-image" />
        <img src={gifAnimation} alt="header" className="absolute-gif" />
        <img src={rightImg} alt="header" className="absolute-image" />
      </div>
    </div>
  );
}

export default Header;
