import React, { useState } from 'react';
import { useLocation } from 'react-router';
import EmailValidation from './components/validation';
import logo from '../../assets/images/logo.png';
import UserForm from './components/userForm';
import CompanyForm from './components/companyForm';
import User from '../../assets/images/user_info.png';
import Company from '../../assets/images/company_info.png';
import Loader from '../../assets/images/loader.png';
import User2 from '../../assets/images/user2.png';
import Loader2 from '../../assets/images/loader2.png';

function Register() {
  const location = useLocation();
  const [formStage, setFormStage] = useState(location?.state?.formStage || 1);
  const [formData, setFormData] = useState();
  const [subTitle, setSubtitle] = useState(
    <>Enjoy the flexibility of managing access from anywhere. <br /> It all starts by creating an account</>,
  );

  const handleUserClick = () => {
    if (formStage !== 3) {
      setFormStage(3);
    }
  };

  const handleCompanyClick = () => {
    if (formData) {
      setFormStage(2);
    }
  };

  return (
    <div className="register-form-wrap">
      <div className="navbar">
        <div className="navbar-left">
          <img src={logo} alt="logo" className="img" />
        </div>
      </div>
      {
        formStage > 1 && (
          <div className="status-container">
            <div className="status-info">
              <div className="user-info">
                <button type="button" className="user-container" onClick={handleCompanyClick}>
                  <img src={formStage === 2 ? Company : User2} alt="Company" className="user-img" />
                  <span className={formStage === 2 && formData ? 'user-content active' : 'user-content'}>Company Info</span>
                </button>
                <img src={formStage === 2 ? Loader : Loader2} alt="Loader" className="loader-img" />
                <div className="user-container">
                  <button type="button" className="user-container" onClick={handleUserClick}>
                    <img src={User} alt="User" className="user-img" />
                    <span className={formStage === 3 ? 'user-content' : 'user-content active'}>User Info</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="register-form">
        <div className="form-header">
          {
            formStage === 1 ? <h1>Welcome to AccessME!</h1> : <h1>Create Your Account!</h1>
          }
          <p>{subTitle}</p>
        </div>
        {formStage === 1
          && (
            <EmailValidation
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
              setSubtitle={setSubtitle}
            />
          )}
        {formStage === 2
          && (
            <CompanyForm
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
              page={location?.state?.page}
              user_email={location?.state?.user_email}
            />
          )}
        {formStage === 3
          && (
            <UserForm
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
            />
          )}
      </div>
    </div>
  );
}

export default Register;
