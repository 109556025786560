import React from 'react';
import {
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';
import Navbar from '../../shared/Navbar';
import Home from '../Home';
import Footer from '../../shared/Footer';
import PrivacyPolicy from '../PrivacyPolicy';
import Register from '../Register';
import DeleteAccount from '../DeleteAccount';

function WrapperRoutes() {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

function Router() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<WrapperRoutes />}>
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/" element={<Home />} />
        </Route>
        <Route exact path="/register" element={<Register />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default Router;
