import React, { useEffect } from 'react';

function DeleteAccount() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    scrollToTop();
  }, []);

  return (
    <div className="delete-wrap">
      <h1>Delete Account</h1>
      <p>
        Please note that if the account is deleted, the following will be done:
        You will not be able to log in again with the same data of your deleted
        account and you will lose all data related to the deleted account and
        you will not be able to return it. You will not be able to access your
        account again when the account is deleted. Please contact us for more
        information at  <a href="mailto:notifications@accessme.io">notifications@accessme.io</a>.
      </p>
    </div>
  );
}

export default DeleteAccount;
