import React, { useEffect, useState } from 'react';
import { City, Country, State } from 'country-state-city';
import Input from '../../../shared/form/Input';
import SelectBox from '../../../shared/form/SelectBox';
import Button from '../../../shared/button';
import auth from '../../../utils/api/auth';

function CompanyForm(props) {
  const [disable, setDisable] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);

  const nextStage = () => {
    const companyData = {
      tenantName: props?.formData?.tenantName,
      email: props?.formData?.companyEmail,
      tax_id: props?.formData?.tax_id,
      address: props?.formData?.address,
      country: props?.formData?.country,
      state: props?.formData?.state,
      city: props?.formData?.city,
      pincode: props?.formData?.pincode,
      mobileNumber: props?.formData?.mobileNumber,
    };
    auth.selfRegister(companyData)
      .then((response) => {
        console.log(response.data.split(':')[1]);
        props?.setFormData({ ...props?.formData, tenantId: response.data.split(':')[1] });
        setUploading(true);
        props?.setFormStage(3);
      })
      .catch(() => {
        setUploading(false);
        setErrorMsg('Unexpected error. please contact support');
      });
  };

  const handleChange = (name, changeValue) => {
    if (changeValue) {
      if (name === 'country') {
        props?.setFormData({
          ...props?.formData,
          country: changeValue.label,
          state: '',
          city: '',
        });
        setCountryCode(changeValue.id);
        const stateData = State.getStatesOfCountry(changeValue.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setStates(stateData);
      }
      if (name === 'state') {
        props?.setFormData({
          ...props?.formData,
          state: changeValue.label,
          city: '',
        });
        const cityData = City.getCitiesOfState(countryCode, changeValue.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCities(cityData);
      }
      if (name === 'city' || name === 'total_device') {
        props?.setFormData({ ...props?.formData, [name]: changeValue.label });
      }
    }
    if (name !== 'country' && name !== 'state' && name !== 'city' && name !== 'total_device') {
      props?.setFormData({ ...props?.formData, [name]: changeValue });
    }
  };

  useEffect(() => {
    setErrorMsg('');
    if (
      props?.formData?.tenantName
      && props?.formData?.tax_id
      && props?.formData?.mobileNumber
      && props?.formData?.companyEmail
      && props?.formData?.address
      && props?.formData?.country
      && props?.formData?.state
      && props?.formData?.city
      && props?.formData?.pincode
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [props?.formData]);

  useEffect(() => {
    const countryData = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(countryData);
  }, []);

  return (
    <div className="company-form-wrap">
      <div className="form-container">
        <div className="single-row">
          <Input
            type="text"
            name="tenantName"
            value={props?.formData?.tenantName}
            change={handleChange}
            placeholder="Company Name"
          />
          <Input
            type="text"
            name="tax_id"
            value={props?.formData?.tax_id}
            change={handleChange}
            placeholder="Company Registration Number"
          />
        </div>
        <div className="single-row">
          <Input
            type="text"
            name="companyEmail"
            value={props?.formData?.companyEmail}
            change={handleChange}
            placeholder="Email Id"
          />
          <Input
            type="number"
            name="mobileNumber"
            value={props?.formData?.mobileNumber}
            change={handleChange}
            placeholder="Phone Number"
          />
        </div>
        <Input
          type="text"
          name="address"
          value={props?.formData?.address}
          change={handleChange}
          placeholder="Address"
        />
        <div className="single-row">
          <SelectBox
            name="country"
            value={props?.formData?.country}
            lists={countries}
            id="controllable-country-demo"
            onchange={handleChange}
            placeholder="Country"
          />
          <SelectBox
            name="state"
            value={props?.formData?.state}
            lists={states}
            id="controllable-state-demo"
            onchange={handleChange}
            placeholder="State"
          />
        </div>
        <div className="single-row">
          <SelectBox
            name="city"
            value={props?.formData?.city}
            lists={cities}
            id="controllable-city-demo"
            onchange={handleChange}
            placeholder="City"
          />
          <Input
            type="number"
            name="pincode"
            value={props?.formData?.pincode}
            change={handleChange}
            placeholder="Pin code"
          />
        </div>
      </div>
      <div className="form-button-group">
        <p className="errorMsg">{errorMsg}</p>
        <div className="form-button">
          <Button
            name="Continue"
            click={nextStage}
            classes="success-button"
            disabled={disable}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyForm;
