import React, { useState } from 'react';
import Input from '../../../shared/form/Input';
import ContactImg from '../../../assets/images/contact.png';
import Button from '../../../shared/button';
import contact from '../../../utils/api/contact';
import ModalBox from '../../../shared/ModalBox';
import Success from '../../../shared/Success';

function TalkToUs() {
  const message = <>Thank you for your enquiry. <br /> We Will contact you soon!</>;
  const [value, setValue] = useState({});
  const [notification, setNotification] = useState(false);
  const handleChange = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  const handleSubmit = () => {
    contact.sendInformation(value)
      .then(() => {
        setValue({
          name: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="contact">
      <ModalBox
        status={notification}
        closeModal={() => setNotification(false)}
        modalView={{ content: <Success message={message} /> }}
        notification
      />
      <img src={ContactImg} alt="contact_image" className="contact-image" />
      <div className="talk-to-us">
        <div className="input-fields">
          <h2>Talk to Us</h2>
          <Input
            name="name"
            label="Name"
            placeholder="Enter your Name"
            type="text"
            value={value.name}
            change={handleChange}
            required
          />
          <Input
            name="email"
            label="Work Email"
            placeholder="Enter your Work Mail "
            type="text"
            value={value.email}
            change={handleChange}
            required
          />
          <Input
            name="phone_number"
            label="Phone number"
            placeholder="Enter your Phone Number"
            type="text"
            value={value.phoneNumber}
            change={handleChange}
            required
          />
          <Input
            className="message-box"
            name="message"
            type="textarea"
            label="Message"
            value={value.message}
            change={handleChange}
          />
          <Button
            type="submit"
            name="Submit"
            click={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default TalkToUs;
