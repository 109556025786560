import React from 'react';
import PropTypes from 'prop-types';

function Button(props) {
  return (
    <div className="btn-wrap">
      {
        props?.loading ? (
          <button
            className="btn"
            type="button"
            disabled
          >
            Loading...
          </button>
        ) : (
          <button
            className="btn"
            type="button"
            onClick={props?.click}
            disabled={props?.disabled}
          >
            {props?.name}
          </button>
        )
      }
    </div>
  );
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
